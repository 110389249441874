// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';

@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import '~./theme/essential/essential';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import '~@ng-select/ng-select/themes/default.theme.css';
// @import "~ionic-selectable/esm5/ionic-selectable.component.min.css";
@import '~leaflet/dist/leaflet.css';

ion-modal .modal-shadow {
  pointer-events: none;
}

#dashboard-search {
  input {
    font-size: 12px !important;
  }

  ion-icon {
    width: 15px;
    margin-left: 5px;
  }
}

ionic-selectable-modal {
  .item.sc-ion-label-ios-h,
  .item .sc-ion-label-ios-h {
    white-space: inherit;
  }

  .item.sc-ion-label-md-h,
  .item .sc-ion-label-md-h {
    white-space: inherit;
  }
}

ion-menu-toggle.nav-bar-dots {
  cursor: pointer;
  margin-left: 16px;

  img {
    width: 20px;
    height: 20px;
  }
}

ion-toolbar.md {
  --ion-safe-area-top: 18px;
}

.antrian-modal {
  &::part(content) {
    position: absolute;
    bottom: 0;
    left: 0;
    min-height: 200px;
    height: auto;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
  }
}

.coupon-list-item-detail {
  &::part(content) {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 400px;
    width: 400px;
    margin-left: -200px;
    margin-top: -200px;
    border-radius: 10px;
  }
}

.coupon-list-modal {
  &::part(content) {
    position: absolute;
    bottom: 0;
    height: 80%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}

.detail-dokter-modal {
  &::part(content) {
    position: absolute;
    height: 53%;
  }
}

.payment-statuses-modal {
  &::part(content) {
    position: absolute;
    bottom: 0;
    height: 80%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}

.transfer-pasien-modal {
  &::part(content) {
    position: absolute;
    width: 90%;
    max-width: 500px;
    height: 354px;
    border-radius: 22px;
  }
}

.cppt-update-modal {
  &::part(content) {
    position: absolute;
    width: 90%;
    max-width: 500px;
    height: 684px;
    border-radius: 22px;
  }
}

.ppn-edit-modal {
  &::part(content) {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 175px;
    width: 300px;
    margin-left: -150px;
    margin-top: -87.5px;
    border-radius: 10px;
  }
}

.sale-list-modal {
  &::part(content) {
    position: absolute;
    bottom: 0;
    height: 80%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}

.visit-history-modal {
  &::part(content) {
    position: absolute;
    bottom: 0;
    height: 65%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}

.processing-edc-modal {
  &::part(content) {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 230px;
    width: 400px;
    margin-left: -200px;
    margin-top: -115px;
    border-radius: 10px;
  }
}

.virtual-account-number-modal {
  &::part(content) {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 240px;
    width: 400px;
    margin-left: -200px;
    margin-top: -120px;
    border-radius: 10px;
  }
}

.appointment-action-modal {
  &::part(content) {
    position: absolute;
    bottom: 0;
    height: 25%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}

.ewallet-transaction-modal {
  &::part(content) {
    --border-radius: 10px;
    position: absolute;
    width: 95%;
    min-height: 30%;
    max-height: 90%;
  }
}

.assesment-modal {
  &::part(content) {
    position: absolute;
    bottom: 0;
    height: 90%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: white;
  }
}

.send-photo-modal {
  &::part(content) {
    --background: transparent !important;
  }
}

.preview-photo-modal {
  &::part(content) {
    --background: transparent !important;
  }
}

.modal-pos-phone {
  &::part(content) {
    --background: transparent !important;
    position: absolute;
    width: 350px;
    height: 215px;
  }
}

.patient-filter-modal {
  &::part(content) {
    position: absolute;
    bottom: 0px;
    height: 65%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
}

.reset-pass-alert .alert-wrapper {
  width: 100%;

  .alert-title,
  .alert-input {
    color: var(--ion-color-text-primary);
  }

  .alert-button-role-cancel {
    color: var(--ion-color-text-secondary);
  }
}

.tolak-cuti-alert .alert-wrapper {
  width: 100%;

  .alert-title,
  .alert-input {
    color: var(--ion-color-text-primary);
  }

  .alert-button-role-cancel {
    color: var(--ion-color-text-secondary);
  }

  .alert-button-role-submit {
    color: var(--ion-color-danger);
  }
}

.update-notes-alert .alert-wrapper {
  width: 100%;

  .alert-title,
  .alert-input {
    color: var(--ion-color-text-primary);
  }

  .alert-button-role-cancel {
    color: var(--ion-color-text-secondary);
  }

  .alert-button-role-submit {
    color: var(--ion-color-primary);
  }
}

.modal-confirmation-alert {
  &::part(content) {
    position: absolute;
    bottom: 0;
    height: 450px;
  }
}

/** MODALS GLOBAL STYLES */

.modal-shorter-height {
  &::part(content) {
    --border-radius: 16px 16px 0px 0px;
    position: absolute;
    bottom: 0;
    min-height: 30%;
    max-height: 45%;
  }
}

.modal-short-height {
  &::part(content) {
    --border-radius: 16px 16px 0px 0px;
    position: absolute;
    bottom: 0;
    height: 45%;
  }
}

.modal-medium-height {
  &::part(content) {
    --border-radius: 16px 16px 0px 0px;
    position: absolute;
    bottom: 0;
    height: 80%;
  }
}

.modal-high-height {
  &::part(content) {
    --border-radius: 16px 16px 0px 0px;
    position: absolute;
    bottom: 0;
    height: 90%;
  }
}

.modal-full-height {
  &::part(content) {
    position: absolute;
    bottom: 0;
    height: 100%;
  }
}

.modal-auto-height {
  &::part(content) {
    position: absolute;
    bottom: 0;
    --border-radius: 16px 16px 0px 0px;
  }

  &.bottom {
    align-items: flex-end;
  }

  --height: auto;

  .ion-page {
    position: relative;
    display: block;
    contain: content;

    .inner-content {
      max-height: 80vh;
      overflow: auto;
      padding: 10px;
    }
  }
}

.modal-full-screen {
  &::part(content) {
    width: 100%;
  }
}

.modal-transparent-bg {
  &::part(content) {
    background: transparent !important;
  }
}

.modal-common {
  &::part(content) {
    --border-radius: 10px 10px 0px 0px;
  }
}

.modal-custom {
  &::part(content) {
    --border-radius: 32px 32px 32px 32px;
    width: 514px;
    height: 500px;
  }
}

.modal-auto-height-middle {
  &::part(content) {
    --border-radius: 10px;
    position: absolute;
    width: auto;
    max-width: 600px;
    height: auto;
    margin-left: 20px;
    margin-right: 20px;
  }
}

/** END MODAL STYLES */

.bg-circle {
  background-image: url('./assets/images/ui/bg-dashboard.png');
  background-color: var(--ion-color-primary);
  background-position: top 0px right 0px;
  // overflow: initial;

  position: fixed;
  z-index: 0;

  top: 0px;
  height: 250px;
  width: 100%;
  background-repeat: no-repeat;
}

ion-toolbar.bg-primary {
  --background: transparent;
  background-image: url('./assets/images/ui/bg-dashboard.png');
  background-color: var(--ion-color-primary);
  background-position: top 0px right 0px;
  background-repeat: no-repeat;

  ion-back-button,
  ion-title {
    color: var(--ion-color-text-white);
    font-size: 18px;
    font-weight: 700;
  }
}

h1,
h2,
h3 {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

.calendar-event-inner {
  background-color: var(--ion-color-primary) !important;
  color: #ffff;
}

ion-picker {
  touch-action: none;
}

app-header-common {
  ion-back-button {
    width: 15px;
    margin-left: 10px;
  }

  h4 {
    margin: 0;
  }
}

app-content-common {
  height: 100%;

  .rounded-spacer {
    h5,
    h4 {
      color: #222;
      margin-bottom: 20px;
    }
  }

  .filter-outline {
    color: #fff;
  }

  ion-card {
    margin: 10px 0;
  }

  ion-item {
    --padding-start: 0px;
  }
}

app-item-card-common {
  display: block;
  margin: 16px 0;

  table.body-table {
    border: none;

    td {
      color: var(--ion-color-text-primary);
      font-size: 0.9rem;
      padding: 4px 0px;
    }

    .key {
      padding-right: 24px;
    }

    .val {
      font-weight: bolder;
    }
  }
}

.global-card-style {
  margin: 10px 0;
}

// Applies a text wrap on ion options
.alert-tappable.alert-radio {
  height: auto;
  contain: content;
}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
  white-space: normal;
}

.sc-ion-modal-md-h:last-of-type {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
}

.sc-ion-modal-ios-h:last-of-type {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
}

// Leaflet Searchbar
.leaflet-control-geosearch {
  width: 100% !important;
  box-shadow: none !important;
  form {
    position: relative;
    width: 100%;
    input {
      min-width: 100%;
      width: 100%;
      border: 2px solid #e8e6f8;
      border-radius: 5px;
      background: #f7f7fd;
      font-size: 12px;
      padding: 6px 10px;
      color: rgba(0, 0, 0, 0.6);
    }

    .reset {
      position: absolute;
      top: 0;
      right: 0;
      height: 35px;
      border-radius: 0px 10px 10px 0px;
      background: transparent;
      padding-right: 10px;
    }

    .results.active {
      font-size: 12px;
      padding: 10px;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
      border-radius: 0px 0px 10px 10px;
    }
  }
  a {
    height: 0;
  }
}

.leaflet-control-geosearch .results > * {
  line-height: 24px;
  border: 1px solid transparent;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
