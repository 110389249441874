/*
 * Load Dark/Light theme
 */
@import "~./essential-light";
// @import "~theme/essential/essential-dark";

/*
* Default Ionic colors overridden by Essential's color scheme. New colors
* added into the Essential theme such as "mute" shades.
*/
:root {
  /** Text **/
  --ion-color-text-primary: #222;
  --ion-color-text-secondary: #777;
  --ion-color-text-light: #bebebe;
  --ion-color-text-white: #fff;

  /** primary **/
  // --ion-color-primary: var(--ion-color-primary);
  --ion-color-primary: #09A189;
  --ion-color-primary-rgb: 65,177,155;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #088e79;
  --ion-color-primary-tint: #22aa95;
  --ion-color-primary-light: #F2FBF9;
  --ion-color-primary-bg: rgba(9, 161, 137, 0.2);
  // --ion-color-primary-bg: rgba(65, 177, 155, 0.1);

  /** secondary **/
  --ion-color-secondary: #e64d6c;
  --ion-color-secondary-rgb: 230,77,108;
  --ion-color-secondary-contrast: #fff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #ca445f;
  --ion-color-secondary-tint: #e95f7b;
  --ion-color-secondary-bg: rgba(230, 77, 108, 0.2);

  /** tertiary **/
  --ion-color-tertiary: #4776AB;
  --ion-color-tertiary-rgb: 71,118,171;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #3e6896;
  --ion-color-tertiary-tint: #5984b3;
  --ion-color-tertiary-bg: rgba(71, 118, 171, 0.2);

  /** Gray Shading Colors, e.g. for borders, shadow, etc. **/
  --ion-color-shade-heavy: #7a7a7a;
  --ion-color-shade-medium: #bdbdbd;
  --ion-color-shade-light: #dedede;
  --ion-color-shade-lighter: #efefef;
  --ion-color-shade-lightest: #f8f8f8;

  /** success **/
  --ion-color-success: #97C66B;
  --ion-color-success-rgb: 198, 107, 196;
  --ion-color-success-contrast: var(--ion-color-success-contrast-theme);
  --ion-color-success-contrast-rgb: var(--ion-color-success-contrast-rgb-theme);
  --ion-color-success-shade: #85ae5e;
  --ion-color-success-tint: #a1cc7a;

  /** warning **/
  --ion-color-warning: #F39B44;
  --ion-color-warning-rgb: 255,196,9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #d6883c;
  --ion-color-warning-tint: #f4a557;
  --ion-color-warning-light: #fff4d0;
  --ion-color-warning-bg: rgba(243,155,68, 0.2);

  /** danger **/
  --ion-color-danger: #FF788D;
  --ion-color-danger-rgb: 255, 120, 141;
  --ion-color-danger-contrast: var(--ion-color-danger-contrast-theme);
  --ion-color-danger-contrast-rgb: var(--ion-color-danger-contrast-rgb-theme);
  --ion-color-danger-shade: #e06a7c;
  --ion-color-danger-tint: #ff8698;
  --ion-color-danger-light: #ffe6ea;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: var(--ion-color-dark-contrast-theme);
  --ion-color-dark-contrast-rgb: var(--ion-color-dark-contrast-rgb-theme);
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** light **/
  --ion-color-light: #FFDD7D;
  --ion-color-light-rgb: 255, 221, 125;
  --ion-color-light-contrast: var(--ion-color-light-contrast-theme);
  --ion-color-light-contrast-rgb: var(--ion-color-light-contrast-rgb-theme);
  --ion-color-light-shade: #e0c26e;
  --ion-color-light-tint: #ffe08a;

  /** facebook **/
  --ion-color-is-facebook: #3B5998;
  --ion-color-is-facebook-rgb: 35,53,57;
  --ion-color-is-facebook-contrast: var(--ion-color-is-facebook-contrast-theme);
  --ion-color-is-facebook-contrast-rgb: var(--ion-color-is-facebook-contrast-rgb-theme);
  --ion-color-is-facebook-shade: #344e86;
  --ion-color-is-facebook-tint: #4f6aa2;

  /** twitter **/
  --ion-color-is-twitter: #55ACEE;
  --ion-color-is-twitter-rgb: 35,53,57;
  --ion-color-is-twitter-contrast: var(--ion-color-is-twitter-contrast-theme);
  --ion-color-is-twitter-contrast-rgb: var(--ion-color-is-twitter-contrast-rgb-theme);
  --ion-color-is-twitter-shade: #4b97d1;
  --ion-color-is-twitter-tint: #66b4f0;

  /** linkedin **/
  --ion-color-is-linkedin: #0073B0;
  --ion-color-is-linkedin-rgb: 35,53,57;
  --ion-color-is-linkedin-contrast: var(--ion-color-is-linkedin-contrast-theme);
  --ion-color-is-linkedin-contrast-rgb: var(--ion-color-is-linkedin-contrast-rgb-theme);
  --ion-color-is-linkedin-shade: #00659b;
  --ion-color-is-linkedin-tint: #1a81b8;

  /** facebook icon **/
  --ion-color-is-facebook-icon: var(--ion-color-is-facebook-icon-theme);
  --ion-color-is-facebook-rgb: var(--ion-color-is-facebook-icon-rgb-theme);
  --ion-color-is-facebook-contrast: var(--ion-color-is-facebook-icon-contrast-theme);
  --ion-color-is-facebook-contrast-rgb: var(--ion-color-is-facebook-icon-contrast-rgb-theme);
  --ion-color-is-facebook-shade: var(--ion-color-is-facebook-icon-shade-theme);
  --ion-color-is-facebook-tint: var(--ion-color-is-facebook-icon-tint-theme);

  /** twitter icon **/
  --ion-color-is-twitter-icon: var(--ion-color-is-twitter-icon-theme);
  --ion-color-is-twitter-rgb: var(--ion-color-is-twitter-icon-rgb-theme);
  --ion-color-is-twitter-contrast: var(--ion-color-is-twitter-icon-contrast-theme);
  --ion-color-is-twitter-contrast-rgb: var(--ion-color-is-twitter-icon-contrast-rgb-theme);
  --ion-color-is-twitter-shade: var(--ion-color-is-twitter-icon-shade-theme);
  --ion-color-is-twitter-tint: var(--ion-color-is-twitter-icon-tint-theme);

  /** linkedin icon **/
  --ion-color-is-linkedin-icon: var(--ion-color-is-linkedin-icon-theme);
  --ion-color-is-linkedin-rgb: var(--ion-color-is-linkedin-icon-rgb-theme);
  --ion-color-is-linkedin-contrast: var(--ion-color-is-linkedin-icon-contrast-theme);
  --ion-color-is-linkedin-contrast-rgb: var(--ion-color-is-linkedin-icon-contrast-rgb-theme);
  --ion-color-is-linkedin-shade: var(--ion-color-is-linkedin-icon-shade-theme);
  --ion-color-is-linkedin-tint: var(--ion-color-is-linkedin-icon-tint-theme);

  --is-login-element-dark-contrast: #ffffff;
  --ion-color-is-mute-normal: var(--ion-color-is-mute-normal-theme);
  --ion-color-is-mute-dark: var(--ion-color-is-mute-dark-theme);
  --ion-color-is-mute-light: var(--ion-color-is-mute-light-theme);
  --ion-color-is-text-dark: var(--ion-color-is-text-dark-theme);

  // Fonts used by the app for Android and iOS platforms
  --ion-font-family: "Ubuntu", "Roboto", "Helvetica Neue", sans-serif;

  --ion-background-color: var(--ion-background-color-theme);
  --ion-background-color-rgb: var(--ion-background-color-rgb-theme);
  --ion-toolbar-background: var(--ion-toolbar-background-theme);
  --ion-toolbar-color: var(--ion-toolbar-color-theme);
  --placeholder-color: var(--placeholder-color-theme);
  --ion-text-color: var(--ion-text-color-theme);
  --ion-item-color: var(--ion-item-color-theme);
  --is-ion-select-action-sheet-ios-selected-opacity: var(--is-ion-select-action-sheet-ios-selected-opacity-theme);
  --is-list-buttons-detail-icon-opacity: var(--is-list-buttons-detail-icon-opacity-theme);
  --is-item-divider-background: var(--is-item-divider-background-theme);
  --is-item-divider-background-active: var(--is-item-divider-background-active-theme);
  --is-ion-card-background: var(--is-ion-card-background-theme);
  --is-item-in-ion-card-background: var(--is-item-in-ion-card-background-theme);
  --is-ion-popover-ion-backdrop-background: var(--is-ion-popover-ion-backdrop-background-theme);
  --is-handle-background: var(--is-handle-background-theme);
}

// To use custom colors in Ionic Components set the following classes.
// For more information, read
// https://beta.ionicframework.com/docs/theming/advanced/#adding-colors
.ion-color-is-mute-normal {
  --ion-color-base: var(--ion-color-is-mute-normal-theme);
  --ion-color-base-rgb: var(--ion-color-is-mute-normal-rgb-theme);
  --ion-color-contrast: var(--ion-color-is-mute-normal-contrast-theme);
  --ion-color-contrast-rgb: var(--ion-color-is-mute-normal-contrast-rgb-theme);
  --ion-color-shade: var(--ion-color-is-mute-normal-shade-theme);
  --ion-color-tint: var(--ion-color-is-mute-normal-tint-theme);
}

.ion-color-is-text-dark {
  --ion-color-base: var(--ion-color-is-text-dark-theme);
  --ion-color-base-rgb: var(--ion-color-is-text-dark-rgb-theme);
  --ion-color-contrast: var(--ion-color-is-text-dark-contrast-theme);
  --ion-color-contrast-rgb: var(--ion-color-is-text-dark-contrast-rgb-theme);
  --ion-color-shade: var(--ion-color-is-text-dark-shade-theme);
  --ion-color-tint: var(--ion-color-is-text-dark-tint-theme);
}

.ion-color-is-facebook {
  --ion-color-base: #3B5998;
  --ion-color-base-rgb: 35,53,57;
  --ion-color-contrast: var(--ion-color-is-facebook-contrast-theme);
  --ion-color-contrast-rgb: var(--ion-color-is-facebook-contrast-rgb-theme);
  --ion-color-shade: #344e86;
  --ion-color-tint: #4f6aa2;
}

.ion-color-is-facebook-icon {
  --ion-color-base: var(--ion-color-is-facebook-icon-theme);
  --ion-color-base-rgb: var(--ion-color-is-facebook-icon-rgb-theme);
  --ion-color-contrast: var(--ion-color-is-facebook-icon-contrast-theme);
  --ion-color-contrast-rgb: var(--ion-color-is-facebook-icon-contrast-rgb-theme);
  --ion-color-shade: var(--ion-color-is-facebook-icon-shade-theme);
  --ion-color-tint: var(--ion-color-is-facebook-icon-tint-theme);
  }

.ion-color-is-twitter {
  --ion-color-base: #55ACEE;
  --ion-color-base-rgb: 35,53,57;
  --ion-color-contrast: var(--ion-color-is-twitter-contrast-theme);
  --ion-color-contrast-rgb: var(--ion-color-is-twitter-contrast-rgb-theme);
  --ion-color-shade: #4b97d1;
  --ion-color-tint: #66b4f0;
}

.ion-color-is-twitter-icon {
  --ion-color-base: var(--ion-color-is-twitter-icon-theme);
  --ion-color-base-rgb: var(--ion-color-is-twitter-icon-rgb-theme);
  --ion-color-contrast: var(--ion-color-is-twitter-icon-contrast-theme);
  --ion-color-contrast-rgb: var(--ion-color-is-twitter-icon-contrast-rgb-theme);
  --ion-color-shade: var(--ion-color-is-twitter-icon-shade-theme);
  --ion-color-tint: var(--ion-color-is-twitter-icon-tint-theme);
}

.ion-color-is-linkedin {
  --ion-color-base: #0073B0;
  --ion-color-base-rgb: 35,53,57;
  --ion-color-contrast: var(--ion-color-is-linkedin-contrast-theme);
  --ion-color-contrast-rgb: var(--ion-color-is-linkedin-contrast-rgb-theme);
  --ion-color-shade: #00659b;
  --ion-color-tint: #1a81b8;
}

.ion-color-is-linkedin-icon {
  --ion-color-base: var(--ion-color-is-linkedin-icon-theme);
  --ion-color-base-rgb: var(--ion-color-is-linkedin-icon-rgb-theme);
  --ion-color-contrast: var(--ion-color-is-linkedin-icon-contrast-theme);
  --ion-color-contrast-rgb: var(--ion-color-is-linkedin-icon-contrast-rgb-theme);
  --ion-color-shade: var(--ion-color-is-linkedin-icon-shade-theme);
  --ion-color-tint: var(--ion-color-is-linkedin-icon-tint-theme);
}

/*
* Added colors to be used less frequently. They don't
* use the color function. For more information, read
* https://ionicframework.com/docs/theming/theming-your-app/
*/
$is-searchbar-color: white;

@import "./core";
// @import "./rubik";
@import "./ubuntu";
@import "./grid";
@import "./list";
@import "./list-square";
@import "./list-condensed";
@import "./list-colored";
@import "./list-ordered";
@import "./list-item-start-buttons-end";
@import "./payment-cards";
@import "./product-tile";
@import "./collapsible-list-with-divider";
